import { initialize, changeUser, logCustomEvent, getUser, automaticallyShowInAppMessages, openSession } from '@braze/web-sdk';
import IBooking from '../interfaces/IBooking';
import { ICharge } from '../interfaces/ICharge';
import ISite from '../interfaces/ISite';
import regionConverter from './regionConverter';
import { IDiscountCode } from '../interfaces/IDiscountCode';

export class BrazeEvents {

    public static NewsletterGroup: string = "";
    public static UpdatesGroup: string = "";
    public static OffersGroup: string = "";
    public static AuSmsGroup: string = "";

    public static AuNumber: string = "+61";

    public static Init(
        apiKey: string,
        endpoint: string,
        newsletterGroup: string,
        updatesGroup: string,
        offersGroup: string,
        auSmsGroup: string) {
        initialize(apiKey, {
            baseUrl: endpoint,
            allowUserSuppliedJavascript: true,
        });

        automaticallyShowInAppMessages();
        openSession();

        BrazeEvents.NewsletterGroup = newsletterGroup;
        BrazeEvents.UpdatesGroup = updatesGroup;
        BrazeEvents.OffersGroup = offersGroup;
        BrazeEvents.AuSmsGroup = auSmsGroup;
    }

    public static ChangeUser(
        playerGuid: string,
        playerEmail: string,
        firstName: string,
        lastName: string,
        venueId: number,
        venueName: string,
        phoneNumber: string | null,
        site: ISite,
        language: string,

    )
    {
        const region = regionConverter.regionIdToString(site.regionId);

        changeUser(playerGuid.toUpperCase());

        const user = getUser();

        if (!user) {
            return
        }

        const lang_short = language.substring(0, 2);

        user.setEmail(playerEmail);
        user.setFirstName(firstName);
        user.setLastName(lastName);
        user.setLanguage(lang_short);
        user.setCustomUserAttribute("latest_venue_id", venueId);
        user.setCustomUserAttribute("latest_venue_code", site.code);
        user.setCustomUserAttribute("latest_venue_name", venueName);
        user.setCustomUserAttribute("latest_venue_country", site.country);
        user.setCustomUserAttribute("latest_venue_region", region);
        user.setCustomUserAttribute("latest_data_source", "BookingSystem");
        if (phoneNumber) {
            user.setPhoneNumber(phoneNumber);          
        }
    }

    public static CartAdded() {
        logCustomEvent("cart_added");
    }

    public static CheckoutInitiated(
        isEventBooking:  boolean,
        charge: ICharge,
        site: ISite,
        language: string,
        isReservation: boolean,
        isGiftVoucher: boolean,
        game_package: string,
        booking?: IBooking,
    ) {
        const region = regionConverter.regionIdToString(site.regionId);
        const lang_short = language.substring(0, 2);

        logCustomEvent("checkout_initiated", {
            package_name: game_package ?? "N/A",
            site_code: site.code,
            site_name: site.name,
            language: lang_short,
            currency_code: charge.currencyCode,
            ticket_quantity: charge.quantity,
            session_datetime: booking?.startTime ?? "N/A",
            total: charge.total,
            cost: charge.cost,
            tax: charge.tax,
            is_reservation: isReservation,
            is_gift: isGiftVoucher,
            is_event_booking: isEventBooking,
        });
    }

    public static DiscountAdded(
        discount: IDiscountCode) {
        logCustomEvent("discount_added", {
            is_gift_voucher: discount.isGiftVoucher,
            discount: discount.amount,
            code_used: discount.isCodeVoucher
        });
    }

    public static PaymentInitiated(
        isEmailSubscribed: boolean,
        isSmsSubscribed: boolean,
        isAuPhoneNumber: boolean,
        isReservation: boolean,
        isGiftVoucher: boolean,
        charge: ICharge,
        country: string,
        siteName: string
    ) {
        logCustomEvent("payment_initiated", {
            total: charge.total,
            tax: charge.tax,
            fee: charge.fee,
            is_reservation: isReservation,
            is_gift: isGiftVoucher,
        });

        const user = getUser();

        if (!user) {
            return
        }

        user.setCustomUserAttribute("latest_data_source", "BookingSystem");

        if (isEmailSubscribed) {
            user.setEmailNotificationSubscriptionType("opted_in");
            user.setCustomUserAttribute("latest_email_optin_source", { site_name: siteName, data_source: "BookingSystem" });
            user.addToSubscriptionGroup(BrazeEvents.NewsletterGroup);
            user.addToSubscriptionGroup(BrazeEvents.UpdatesGroup);
            user.addToSubscriptionGroup(BrazeEvents.OffersGroup);
        }
        if (isSmsSubscribed) {
            user.setCustomUserAttribute("opt_in_sms", true);
            user.setCustomUserAttribute("opt_in_sms_country", country);
            user.setCustomUserAttribute("latest_sms_optin_source", { site_name: siteName, data_source: "BookingSystem" } );
            if (isAuPhoneNumber) {
                user.addToSubscriptionGroup(BrazeEvents.AuSmsGroup);
            }
        }
    }

    public static ModifyCart() {
        logCustomEvent("modify_cart");
    }
}

export default BrazeEvents;